<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="发票开始日期"
          end-placeholder="发票结束日期"
        />
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange2"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="应收开始日期"
          end-placeholder="应收结束日期"
        />
        <el-button type="primary" size="small" icon="el-icon-search" @click="getTableData"> 查询</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="refresh()"> 刷新</el-button>
        <el-button type="success" size="small" icon="el-icon-upload2" @click="exportToExcel()">导出Excel</el-button>
        <el-button type="danger" size="small" @click="changeStatus">结束收款</el-button>
      </div>
      <search-table
        id="multiTable"
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'soin_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :show-summary="true"
        :selectable="row => row.payment_status !== 3"
        :summariesColumns="['soll_subrece', 'soll_reced_total', 'soll_unreced']"
        @selection-change="handleSelectionChange"
        @getTableData="getTableData"
      >
        <template v-slot:payment_status="scope">
          <div style="text-align: center">
            <el-tag type="info" size="mini" v-if="scope.row.payment_status === 0">{{ formatStatus(scope.row.payment_status) }}</el-tag>
            <el-tag type="success" size="mini" v-if="scope.row.payment_status === 2">{{ formatStatus(scope.row.payment_status) }}</el-tag>
            <el-tag type="danger" size="mini" v-if="scope.row.payment_status === 3">{{ formatStatus(scope.row.payment_status) }}</el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch } from '@api/request';
import { stisAPI } from '@api/modules/stis';
import { stffAPI } from '@/api/modules/staff';
import { custAPI } from '@/api/modules/cust';
import { formatStatus, soinTableProperties } from '@/views/SettleManagement/StisManage/stis';
import SearchTable from '@/components/table/SearchTableV2.vue';
import htmlToExcel from '@/plugins/htmlToExcel';
import { downloadFile, searchTimeRangeFormatter } from '@api/public';
import { getArrayIds } from '@assets/js/arrayUtils';

export default {
  name: 'StatList',
  components: {
    SearchTable
  },
  data() {
    return {
      dateRange2: [],
      dateRange: [],
      tableProperties: soinTableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      stffUserList: [],
      custOptionList: []
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/stat_edit' || from.path === '/stat_add') {
        this.initData();
      }
    }
  },
  methods: {
    formatStatus,
    initData() {
      this.getTableData();
      // this.getStffUser();
      // this.initCust();
    },
    async getTableData() {
      await (this.loadFlag = true);
      let searchForm = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange || [];
      searchForm.startTime = startTime ? startTime / 1000 : null;
      searchForm.endTime = endTime ? endTime / 1000 : null;
      let [atrat_tran_ppdate, end_tran_ppdate] = this.dateRange2 || [];
      searchForm.atrat_tran_ppdate = atrat_tran_ppdate ? atrat_tran_ppdate / 1000 : null;
      searchForm.end_tran_ppdate = end_tran_ppdate ? end_tran_ppdate / 1000 : null;
      getNoCatch(stisAPI.getSoins, searchForm).then(({ data }) => {
        this.loading = false;
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 获取客户
    initCust() {
      getNoCatch(custAPI.getAllCustsV1).then(({ data }) => {
        this.custOptionList = data.list;
      });
    },
    // 获取人员信息
    getStffUser() {
      getNoCatch(stffAPI.getAllStffsV1).then(({ data }) => {
        this.stffUserList = data;
      });
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    exportToExcel() {
      // htmlToExcel.getExcel(`#multiTable`, 'SH应收账款');
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let searchForm = this.$refs.multiTable.searchForm;
          let [startTime, endTime] = this.dateRange || [];
          searchForm.startTime = startTime ? startTime / 1000 : null;
          searchForm.endTime = endTime ? endTime / 1000 : null;
          let [atrat_tran_ppdate, end_tran_ppdate] = this.dateRange2 || [];
          searchForm.atrat_tran_ppdate = atrat_tran_ppdate ? atrat_tran_ppdate / 1000 : null;
          searchForm.end_tran_ppdate = end_tran_ppdate ? end_tran_ppdate / 1000 : null;
          get(stisAPI.export_soins, searchForm)
            .then(data => {
              if (data.data.code === 0) {
                downloadFile({ fileUrl: data.data.data.url, fileName: data.data.data.fileName, type: '.xlsx' });
                return;
              }
              this.$message.error(data.data.msg);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    },
    changeStatus() {
      if (this.multiSelection.length === 0) return this.$message.warning('至少选择一条信息');
      let isCollected = this.multiSelection.filter(({ soll_unreced }) => soll_unreced > 0).length > 0;
      if (isCollected) {
        this.$confirm('该收款单未收完是否确认结束收款？', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning'
        }).then(() => {
          stisAPI.finishCollection(this.multiSelection).then(() => {
            this.initData();
            this.$message.success('结束收款成功!');
          });
        });
      } else {
        this.$confirm('是否确认结束收款？', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning'
        }).then(() => {
          stisAPI.finishCollection(this.multiSelection).then(() => {
            this.initData();
            this.$message.success('结束收款成功!');
          });
        });
      }
    },
    refresh() {
      this.dateRange = [];
      this.dateRange2 = [];
      this.$refs.multiTable.resetFields();
      this.initData();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
